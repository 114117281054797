import FooterSection from "./FooterSection";
import Slideshow from "./Slideshow";

// LandingPage component definition
const LandingPage = () => {

    return(
        <>
            {/* Rendering the Slideshow component */}
            <Slideshow/>
            {/* Rendering the FooterSection component */}
            <FooterSection/>
        </>
    );
};

// Exporting the LandingPage component as the default export
export default LandingPage; 
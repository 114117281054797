import React, { useState } from 'react';
import "../Style/Packages.css"
import Coffee from "../Images/Coffee.png"

// Function to generate unique elements
const generateUniqueElements = () => {
    // This is just a sample; you can generate elements as per your requirements
    const elements = [];
    for (let i = 0; i < 5; i++) {
        elements.push({
            id: i,
            title: "Packages for 30-08-24",
            text: "Sektion descripeing evetns for the evening",
            images: [
               "Coffee" 
            ]
        });
    };
    return elements;
};

const ExpandableSection = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [elements, setElements] = useState([]);

    const toggleSection = () => {
        if (!isExpanded) {
            setElements(generateUniqueElements());
        }
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <button className='packetElement' onClick={toggleSection}>
                {isExpanded ? 'Collapse' : 'Expand'}
            </button>
            <div>
                {elements.map((element) => (
                    <div key={element.id}>
                        <h3>{element.title}</h3>
                        <p>{element.text}</p>
                        <div>
                            {element.pictures.map((images, index) => (
                                <img key={index} src={images} alt={`Unique ${element.id} ${index}`} />
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExpandableSection;

// Import the Deals component from the Deals.jsx file
import Deals from "../Section/Deals.jsx"

// Define a function component named Packages
const Packages = () => {

    // Return a JSX element that renders the Deals component
    return(
        // Render the Deals component
        <Deals/>
    )
}

// Export the Packages component as the default export
export default Packages;
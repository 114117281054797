import React from "react";
import Sponcors from "./Sponcors";
import "../Style/SponcorSection.css"
import JohnWaldorf from "../Images/JohnWaldorf.png";
import Realtor from "../Images/realtor.png";
import Cruzio from "../Images/Cruzio_Logo.png";
import Skate from "../Images/Skate.png";
import Hair from "../Images/Hair.png";
import Farm from "../Images/Farm.png";
import Plumbing from "../Images/Plumbing.png";
import Music from "../Images/Music.png";
import Garden from "../Images/Garden.png";
import Mountain from "../Images/Mountain.png";
import CabCafe from "../Images/CabCafe.png";
import Santa from "../Images/Santa.png";
import Sock from "../Images/Sock.png";
import CoinExchange from "../Images/CoinExchange.png";
import Electric from "../Images/Electric.png";
import Calderon from "../Images/Calderon.png";
import Streetlight from "../Images/Streetlight.png";
import CafeSparrow from "../Images/CafeSparrow.png";
import Coffee from "../Images/Coffee.png";
import FooterSection from "./FooterSection";

// Function component that represents the SponcorSection component
const SponcorSection = (props) => {

    // Define an array of sponsor objects
    const section = [
        {
            imageSrc:
                JohnWaldorf,
            text1: "John Warldorf Construction",
            text2: "Specializing in residential remodels and additions since 1989.",
            buttons: [
                { label: "Webiste", url: "http://johnwaldorfconstruction.com" },
            ]
        },
        {
            imageSrc: Realtor,
            text1: "Darrel Louis & Janet Romanowski, Realtors",
            text2: "Finding a Home for Good Music",
            buttons: [
                { label: "Webiste", url: "http://janetromanowski.agent.davidlyngmoxiworks.com/home/?ID=7559" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/janet-romanowski-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Cruzio,
            text1: "Cruzio Internet",
            text2: "Internet Service Providers",
            buttons: [
                { label: "Website", url: "https://cruzio.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/cruzio-internet-santa-cruz-2" }
            ]
        },
        {
            imageSrc:
                Skate,
            text1: "Santa Cruz Skateboards",
            text2: "Visit us at: Santa Cruz Boardroom on 41st Ave, Bill's Wheels Skate Shop on Soquel Ave, or Pacific Wave in the Pacific Garden Mall",
            buttons: [
                { label: "Website", url: "https://nhsskatedirect.com" },
            ]
        },
        {
            imageSrc:
                Hair,
            text1: "Santa Cruz Hair Design",
            text2: "Hairstyles that make you feel like a million bucks!",
            buttons: [
                { label: "Website", url: "https://www.santacruzhairdesign.com" },
            ]
        },
        {
            imageSrc:
                Farm,
            text1: "SunRidge Farms",
            text2: "Growing Organic and Natural Foods",
            buttons: [
                { label: "Website", url: "https://shopsunridgefarms.com" },
            ]
        },
        {
            imageSrc:
                CabCafe,
            text1: "Gabriella Café",
            text2: "The town’s most romantic spot!",
            buttons: [
                { label: "Website", url: "http://gabriellacafe.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/gabriella-cafe-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Plumbing,
            text1: "Affordable Plumbing & Drain",
            text2: "Taking Care of Community Needs",
            buttons: [
                { label: "Website", url: "https://www.affordableplumbingsantacruz.com/index.html" },
            ]
        },
        {
            imageSrc:
                Music,
            text1: "More Music",
            text2: "Buy Sell Trade Repair Stringed Instruments",
            buttons: [
                { label: "Website", url: "http://www.musicstoresantacruz.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/more-music-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Garden,
            text1: "Knox Garden Box",
            text2: "Elevate Your Gardening Experience",
            buttons: [
                { label: "Website", url: "https://www.knoxroofing.com/services/knox-garden-box-and-wood-shop/" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/knox-garden-box-scotts-valley-2" }
            ]
        },
        {
            imageSrc:
                Mountain,
            text1: "Mountain Feed and Farm Supply",
            text2: "Grow Your Own Food & Raise Livestock",
            buttons: [
                { label: "Website", url: "https://www.mountainfeed.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/mountain-feed-and-farm-supply-ben-lomond" }
            ]
        },
        {
            imageSrc:
                Santa,
            text1: "Santa Cruz Farmers Market",
            text2: "Best in Regional Organic Sustainable Foods",
            buttons: [
                { label: "Website", url: "http://www.santacruzfarmersmarket.org" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/santa-cruz-farmers-market-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Sock,
            text1: "Sock Shop and Shoe Company",
            text2: "Hello footwear lovers!",
            buttons: [
                { label: "Website", url: "https://www.sockshopandshoeco.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/sockshop-and-shoe-company-santa-cruz#query:Sock%20Shop%20and%20Shoe%20Company" }
            ]
        },
        {
            imageSrc:
                CoinExchange,
            text1: "Santa Cruz Coin Exchange",
            text2: "Buy sell gold, silver, platinum bullion, jewelry",
            buttons: [
                { label: "Website", url: "http://www.santacruzgold.biz/index.html" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/santa-cruz-coin-exchange-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Electric,
            text1: "Giant Electric",
            text2: "Electrical Contractor (831) 465-6591",
            buttons: [
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/giant-electric-santa-cruz" }
            ]
        },
        {
            imageSrc:
                Calderon,
            text1: "Dr. Lawrence Calderon",
            text2: "Chiropractor 465-9344",
            buttons: [
                { label: "Give em a Yalp", url: "https://www.yelp.dk" }
            ]
        },
        {
            imageSrc:
                Streetlight,
            text1: "Streetlight Records",
            text2: "We Buy Music, Movies, Video Games",
            buttons: [
                { label: "Website", url: "https://streetlightrecords.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/streetlight-records-santa-cruz#query:street%20light%20records" }
            ]
        },
        {
            imageSrc:
                CafeSparrow,
            text1: "Cafe Sparrow",
            text2: "Country French Cooking in Aptos",
            buttons: [
                { label: "Website", url: "http://cafesparrow.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/cafe-sparrow-aptos-2" }
            ]
        },
        {
            imageSrc:
                Coffee,
            text1: "Alta Organic Coffee Tea",
            text2: "Organic Coffees Teas with a Conscience",
            buttons: [
                { label: "Website", url: "https://www.altaorganiccoffee.com" },
                { label: "Give em a Yalp", url: "https://www.yelp.com/biz/alta-organic-coffee-and-tea-company-santa-cruz-2" }
            ]
        }
    ];

    // Return a div that contains the sponsor sections
    return (
        <>
            <div className="backgoundStyle">
                <aside className="styleCollum" />
                <div>
                    {section.map((item, index) => (
                        <Sponcors
                            key={index}
                            imageSrc={item.imageSrc}
                            title={item.text1}
                            description={item.text2}
                            buttons={item.buttons}
                        />
                    ))}
                </div>
            </div>
            <FooterSection />
        </>
    );
};

// Export the SponcorSection component as the default export
export default SponcorSection;
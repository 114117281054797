import "../Style/LandingPage.css"
import React, { useState, useEffect, useRef } from 'react';
import image2 from "../Images/Benson1.png"
import image1 from "../Images/paulthorn.png"
import image3 from "../Images/Smither.png"

// Array of images with their respective details
const images = [
    { src: image1, title: "Chris Smither", date: "Experience this July 26" , description: "Snazzy favorite Chris Smither is coming back to Santa Cruz for the first time since 2018!" },
    { src: image2, title: "Stephen Benson", date: "Experience this July 12" , description: "Long active on the New York City music scene and around the world, Stephen Benson is as comfortable at Lincoln Center, the New York Metropolitan Opera, and Carnegie Hall as he is in small ensembles and clubs" },
    { src: image3, title: "Paul Thorn", date: "Experience this July 14" , description: "Snazzy favorite and friend Paul Thorn is back in town this summer! Get your tickets early and often!" }
];

// Slideshow component definition
const Slideshow = () => {
    // State to track the current index of the slideshow
    const [currentIndex, setCurrentIndex] = useState(0);
    // State to track if the slide is animating
    const [isAnimating, setIsAnimating] = useState(false);
    // Reference to store the interval ID for the slideshow
    const slideInterval = useRef(null);

    // Effect hook to start the slideshow on component mount and clean up on unmount
    useEffect(() => {
        startSlideShow();
        return () => stopSlideShow();
    }, []);

    // Function to start the slideshow
    const startSlideShow = () => {
        slideInterval.current = setInterval(() => {
            setIsAnimating(true); // Set animating state to true
            setTimeout(() => {
                setIsAnimating(false); // Set animating state to false after a timeout
                setCurrentIndex(prevIndex => (prevIndex + 1) % images.length); // Update currentIndex
            }, 5800); // Duration of animation
        }, 6000); // Interval duration
    };

    // Function to stop the slideshow
    const stopSlideShow = () => {
        clearInterval(slideInterval.current);
    };

    // Function to handle click on text items
    const handleTextClick = (index) => {
        setIsAnimating(false);
        setCurrentIndex(index);
        stopSlideShow();
        startSlideShow();
    };

    return (
        <div className="slideshow">
            {/* Container for the slideshow image */}
            <div className="slideshow-image-container">
                <img src={images[currentIndex].src} alt={images[currentIndex].alt} className="slideshow-image" />
            </div>
            {/* Container for the description */}
            <div className="description-container">
                <h2 className="description-title">{images[currentIndex].title}</h2>
                <p className="description-text">{images[currentIndex].description}</p>
                <p className="description-text">{images[currentIndex].date}</p>
            </div>
            {/* Container for the text items */}
            <div className="text-container">
                {images.map((image, index) => (
                    <div className="text-item" key={index} onClick={() => handleTextClick(index)}>
                        <span className={`text-title ${index === currentIndex ? 'active' : ''}`}>{image.title}</span>
                        {index === currentIndex && <div className={`loading-bar ${isAnimating ? 'animate' : ''}`}></div>}
                    </div>
                ))}
            </div>
        </div>
    );
};
export default Slideshow; 
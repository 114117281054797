import React, { useState } from 'react';
import './App.css';
import AboutSection from './Section/AboutSection';
import HeaderSection from './Section/HeaderSection'
import LandingPage from './Section/LandingPage';
import SponcorSection from './Section/SponcorSection';
import Packages from "./Section/Packages";

// Function component that represents the main application¢¢
function App() {
    // Use the useState hook to create state variables for each section of the webpage
    const [toggleHome, setToggleHome] = useState(true);
    const [toggleAbout, setToggleAbout] = useState(false);
    const [toggleSponsor, setToggleSponsor] = useState(false);
    const [toggleEvent, setToggleEvent] = useState(false);
    const [togglePackages, setTogglePackages] = useState(false);

    // Return a div that contains the HeaderSection component and the currently active section of the webpage
    return (
        <div>
            {/* Render the HeaderSection component and pass in the state variables as props */}
            <HeaderSection
                setToggleHome={setToggleHome}
                setToggleAbout={setToggleAbout}
                setToggleSponsor={setToggleSponsor}
                setToggleEvent={setToggleEvent}
                setTogglePackages={setTogglePackages}
            />
            {/* Use conditional rendering to display the appropriate section of the webpage based on the state variables */}
            {toggleHome && <LandingPage />}
            {toggleAbout && <AboutSection />}
            {toggleSponsor && <SponcorSection />}
            {togglePackages && <Packages />}
        </div>
    );
};

// Export the App component as the default export
export default App;
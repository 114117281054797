import "../Style/AboutSection.css"
import profilePic from '../Images/ProfilePic.png'
import FooterSection from "./FooterSection";

// Function component that represents the AboutSection of the webpage
const AboutSection = (props) => {

    // Return a div that contains the profile picture and text about the company
    return (
        <>
            <div className="backgroundStyle">
                <aside className="styleCollum" />
                <div className="aboutSection">
                    {/* Render the profile picture */}
                    <img className="profilePic" src={profilePic} alt="ProfilePictor" />
                    <article className="textSection1">
                        {/* Render the text about the company */}
                        <p>SnazzyProductions.com Live Music Concerts
                            Santa Cruz, CA – San Jose, CA – San Francisco & Monterey Bay Areas</p>
                        <p>Snazzy Productions, was created over three decades ago by Sleepy John Sandidge, who felt Santa Cruz needed alternative music concerts.
                            With his long history as an on-air radio personality, and his experience as a stage manager for some of the area’s largest concerts
                            (including Willie Nelson and the Grateful Dead), Sleepy John formed Snazzy Productions.</p>
                        <p> Since its inception, Snazzy Productions has produced over 700 events with artists as diverse as Jesse Winchester,
                            The Persuasions, Norton Buffalo, and Bo Diddley. John Sandidge (known as ‘Sleepy John’) has brought many new exciting performers to the
                            Santa Cruz Monterey Bay California area. In the process he has helped developing music artists in and around Santa Cruz and Monterey Bay
                            regions to become recognized leaders in their unique styles of musical performance.</p>
                        <p>SnazzyProductions.com takes advantage of many Santa Cruz’s best music venues, including the Kuumbwa Jazz Center and the Rio
                            Theatre in in downtown Santa Cruz, as well as Don Quixote’s Night Club in Felton, and other local venues including live concerts at the
                            Santa Cruz Beach Boardwalk Cocoanut Grove and the Santa Cruz Civic Auditorium. Join us for one our live music concerts at any of these
                            wonderful locations.</p>
                    </article>
                </div>
            </div>
            <FooterSection />
        </>
    );
};

// Export the AboutSection component as the default export
export default AboutSection; 
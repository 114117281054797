import React, { useState } from 'react';
import logo from '../Images/Aktiv_4.png';
import "../Style/HeaderSection.css";

// Function component that represents the HeaderSection of the webpage
const HeaderSection = (props) => {
    // Use the useState hook to create a state variable to track the active button
    const [activeButton, setActiveButton] = useState('Home');

    // Function to handle button clicks and update the active button state
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        switch (buttonName) {
            // Update the props based on the button clicked
            case 'Home':
                props.setToggleHome(true);
                props.setToggleAbout(false);
                props.setToggleSponsor(false);
                props.setToggleEvent(false);
                props.setTogglePackages(false);
                break;
            case 'About':
                props.setToggleHome(false);
                props.setToggleAbout(true);
                props.setToggleSponsor(false);
                props.setToggleEvent(false);
                props.setTogglePackages(false);
                break;
            case 'Sponsor':
                props.setToggleHome(false);
                props.setToggleAbout(false);
                props.setToggleSponsor(true);
                props.setToggleEvent(false);
                props.setTogglePackages(false);
                break;
            case 'Event':
                props.setToggleHome(false);
                props.setToggleAbout(false);
                props.setToggleSponsor(false);
                props.setToggleEvent(true);
                props.setTogglePackages(false);
                break;
            case 'Packages':
                props.setToggleHome(false);
                props.setToggleAbout(false);
                props.setToggleSponsor(false);
                props.setToggleEvent(false);
                props.setTogglePackages(true);
                break;
            default:
                break;
        }
    };

    // Return a div that contains the logo and navigation menu
    return (
        <div className="headerSection">
            {/* Render the logo image */}
            <img onClick={() => handleButtonClick('Home')} className='logoImage' src={logo} alt="Logo"/>
            <div className='navigationMenu'>
                {/* Render the navigation buttons */}
                <button 
                    className={`navigationButton ${activeButton === 'Home' ? 'active' : ''}`} 
                    onClick={() => handleButtonClick('Home')}>
                    Home
                </button>

                <button 
                    className={`navigationButton ${activeButton === 'About' ? 'active' : ''}`} 
                    onClick={() => handleButtonClick('About')}>
                    About
                </button>        

                <button 
                    className={`navigationButton ${activeButton === 'Sponsor' ? 'active' : ''}`} 
                    onClick={() => handleButtonClick('Sponsor')}>
                    Sponsor
                </button>

                <button 
                    className={`navigationButton ${activeButton === 'Event' ? 'active' : ''}`} 
                    onClick={() => handleButtonClick('Event')}>
                    Event
                </button>

                <button 
                    className={`navigationButton ${activeButton === 'Packages' ? 'active' : ''}`} 
                    onClick={() => handleButtonClick('Packages')}>
                    Packages
                </button>
            </div>
        </div>
    );
};

// Export the HeaderSection component as the default export
export default HeaderSection;
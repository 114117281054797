import "../Style/FooterSection.css"
import instagramLogo from "../Images/icons8-instagram.svg"
import facebookLogo from "../Images/icons8-facebook.svg"
import linkedinLogo from "../Images/icons8-linkedin.svg"

// Function component that represents the FooterSection of the webpage
const FooterSection = (props) => {

    // Return a footer element that contains contact information, social media links, and a newsletter sign-up form
    return (
        <footer className="fullFooter">
            <div className="footerContact">
                {/* Render the contact information */}
                <h2>Contact Information</h2>
                <h4>Ph: (555) 555-1234 </h4>
                <h4>Email: Snazzy@hotmail.com</h4>
            </div>
            <div className="footerBrands">
                {/* Render the social media links */}
                <img className="brandLogo" src={instagramLogo} alt="instagramLogo"/>
                <img className="brandLogo" src={facebookLogo} alt="facebookLogo"/>
                <img className="brandLogo" src={linkedinLogo} alt="linkedinLogo"/>
            </div>
            <div className="footerNewsletter">
                {/* Render the newsletter sign-up form */}
                <h2>Sign up for the Snazzy newsletter</h2>
                <input className="footerNewsletterInput" type="text" placeholder="exampel@mail.com"></input>
            </div>
        </footer>
    );
};

// Export the FooterSection component as the default export
export default FooterSection;
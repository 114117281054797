import "../Style/Sponcors.css"

// Function component that represents a single sponsor
const Sponcors = (props) => {

    // Return a div that contains the sponsor image, title, description, and buttons
    return (
        <>
        <hr className="hrStyle"/>
        <div className="sponcorElement">
            {/* Render the sponsor image */}
            <img className="sponcorImage" src={props.imageSrc} alt="SponcorImage" />
            <article className="sponcorText">
                {/* Render the sponsor title and description */}
                <h2>{props.title}</h2>
                <p>{props.description}</p>
            </article>
            <div className="buttonContainer">
                {/* Render the sponsor buttons */}
                {props.buttons.map((button, index) => (
                    <a key={index} href={button.url} target="_blank" rel="noopener noreferrer">
                        <button className="sponcorButton" style={button.style}>{button.label}</button>
                    </a>
                ))}
            </div>
        </div>
        </>
    );
};

// Export the Sponcors component as the default export
export default Sponcors;